import service from '@/until/api'

// 查询表格数据
export function _getLogLists(data) {
  return service({
    url: '/system-nacos/auditlog/get',
    method: 'POST',
    data: data
  })
}


// 查询组织
export function _getCompanyId(data) {
  return service({
    url: '/user-nacos/company/findCompany/relation',
    method: 'GET',
    params: data 
  })
}

export function _getoperationType(data) {
  return service({
    url: '/system-nacos/auditlog/operation/list',
    method: 'GET',
    params: data 
  })
}

//导出查询的列表数据
export function _Download(data) {
  return service({
      url: '/file-nacos/excelDownload/exportLogRecord',
      method: 'POST',
      data,
      responseType: 'blob'
  })
}








<template>
<div>
    <p class="txetTop">
        <span
          :class="showCenter == true ? 'statutext' : 'textcolor'"
          @click="getCenterCloud"
          >中心云</span
        >
        <span
          :class="showCenter == false ?  'statutext' : 'textcolor'"
          @click="getSaasCloud"
          >SaaS云</span
        >
    </p>
    <!-- 中心 -->
    <el-form :inline="true" class="txetTopB" v-if="showCenter == true">
      <el-form-item>
           <el-input v-model="searchA.resource" placeholder="请输入操作项" size="medium" class="centerInput"></el-input>       
      </el-form-item>         
      <el-form-item>
          <el-date-picker
                    v-model="searchA.operationTime"
                    type="daterange"
                    range-separator="-"
                    start-placeholder="开始日期"
                    end-placeholder="结束日期"
                    size="medium"
                    value-format="yyyy-MM-dd"
                    class="pickerTime"
                    >
            </el-date-picker>          
      </el-form-item>
      <el-form-item>
           <el-select v-model="searchA.operationType" placeholder="请选择操作" style="width:100%" size="medium" class="centerSelInput ipwidth">
            <el-option
                v-for="item in operationTypeListA"
                :key="item.value"
                :label="item.label"
                :value="item.value">
            </el-option>
          </el-select>
      </el-form-item>
      <el-form-item>
            <el-button type="primary" size="medium" class="centerCzbtn ml20" @click="searchCenter()">搜索</el-button>
            <el-button type="primary" size="medium" @click="clearSearch" class="centerBtn resetbtn">重置</el-button>
            <el-button type="primary" size="medium" class="centerCzbtn ml20" @click="getDowload()">导出记录</el-button>
      </el-form-item>
    </el-form>

    <!-- saaa -->
    <el-form :inline="true" class="txetTopB" v-else>
         <el-form-item>
           <el-select v-model="searchB.companyId" placeholder="所属组织" style="width:100%" size="medium" class="centerSelInput ipwidth">
            <el-option
                v-for="item in companyIdList"
                :key="item.value"
                :label="item.companyName"
                :value="item.id">
            </el-option>
          </el-select>
      </el-form-item>
       <el-form-item>
           <el-select v-model="searchB.operationType" placeholder="请选择操作" style="width:100%" size="medium" class="centerSelInput ipwidth">
            <el-option
                v-for="item in operationTypeListB"
                :key="item.value"
                :label="item.label"
                :value="item.value">
            </el-option>
          </el-select>
      </el-form-item>
      <el-form-item>
           <el-input v-model="searchB.resource" placeholder="请输入操作项" size="medium" class="centerInput"></el-input>       
      </el-form-item>         
      <el-form-item>
          <el-date-picker
                    v-model="searchB.operationTime"
                    type="daterange"
                    range-separator="-"
                    start-placeholder="开始日期"
                    end-placeholder="结束日期"
                    size="medium"
                    value-format="yyyy-MM-dd"
                    class="pickerTime"
                    >
            </el-date-picker>          
      </el-form-item>
     
      <el-form-item>
                <el-button type="primary" size="medium" class="centerCzbtn ml20" @click="searchSaas()">搜索</el-button>
                <el-button type="primary" size="medium" @click="clearSearch" class="centerBtn resetbtn">重置</el-button>
                <el-button type="primary" size="medium" class="centerCzbtn ml20" @click="getDowload()">导出记录</el-button>
      </el-form-item>
    </el-form>
    <!-- 表格 -->
     <el-table    :data="showCenter == true ? centerData : saasData"    stripe    style="width: 100%">
        <el-table-column   prop="companyName"    label="所属组织" width="180"  > </el-table-column>
        <el-table-column  prop="operationType"  label="操作类型" width="130"  ></el-table-column>
        <el-table-column  prop="resource"  label="操作项" width="150"></el-table-column>
        <el-table-column  prop="params"  label="参数" width="450"></el-table-column>
        <el-table-column  prop="operationTime"  label="操作时间"></el-table-column>
        <el-table-column  prop="username"  label="操作人"></el-table-column>
    </el-table>
    <!-- 分页信息 -->
    <el-row style="margin:1% 0 ">
        <el-col :span="12" :offset="6">
                <el-pagination
                @size-change="SizeChange"
                @current-change="CurrentChange"
                :current-page="pageNum"
                :page-sizes="[10, 50, 100, 200, 300, 400, 500]"
                :page-size="pageSize"
                layout="total, sizes, prev, pager, next, jumper"
                :total="totalCount">
                </el-pagination>
        </el-col> 
    </el-row> 
        <!-- <Table
      :data1="showCenter == true ? centerData : saasData"
      :columns1="columns"
      :total="totalCount"
      @getAjax="showCenter ? getCenterCloud() : getSaasCloud()"
    /> -->

 </div>   
</template>

<script>
import {
  _getLogLists,
  _getCompanyId,
  _getoperationType,
  _Download
} from "@/api/systemManagement/logManage";

// import  Table  from '../../components/TableTwo.vue' 
export default {
  components: {
    // Table

  },
  data() {
    return {
        RequestData:{//发送请求使用的变量
            resourceType:0,
            pageNum:1,
            pageSize:10,
            companyId: '',
            resource: '',
            operationType:'',
            beginTime: '',
            endTime: ''
       },  
       showCenter: true ,//选择是否展示中心云和saas云   true为中心云 false为saas云
       resourceType: 0,//区分中心云和saas云  默认0     0：中心云 1：saas云
        //分页数据
        pageNum: 1 , //当前页 默认1
        pageSize: 10 ,   //每页的数据   默认10 
        totalCount: 10, //总条数 默认10
        //所属组织 操作下拉框
        companyIdList:[],
        //操作项下拉
        operationTypeListA:[],
        operationTypeListB:[],//saas云
        flag:0,
       //查询
        searchA:{
            resource:'',//操作项
            operationTime:[],//操作时间
            operationType:''//下拉的操作选项
        },
        searchB:{
            companyId:'',//所属组织
            resource:'',//操作项
            operationTime:[],//操作时间
            operationType:''//下拉的操作选项
        },
        //表格数据
        centerData:[],
        saasData:[],
        //存导出表
        exportTable:[]
    };
  },
  created(){
        
  },
  mounted() {
    this.getNowFormatDate();
    this.getoperationTypeCenter()
    this.getoperationTypeSaas()
    this.resourceType = this.showCenter ? 0:1;
    this.getCompanyIdList();
    this.getCenterCloud();

  },

  methods: {
      //m默认时间
      getNowFormatDate() {
           //获取当前日期
            var myDate = new Date();
            var nowY = myDate.getFullYear();
            var nowM = myDate.getMonth()+1;
            var nowD = myDate.getDate();
            //获取三十天前日期
            var lw = new Date(myDate - 1000 * 60 * 60 * 24 * 30);//最后一个数字30可改，30天的意思
            var lastY = lw.getFullYear();
            var lastM = lw.getMonth()+1;
            var lastD = lw.getDate();
            this.searchA.operationTime.push(lastY+"-"+(lastM<10 ? "0" + lastM : lastM)+"-"+(lastD<10 ? "0"+ lastD : lastD));
            this.searchA.operationTime.push(nowY+"-"+(nowM<10 ? "0" + nowM : nowM)+"-"+(nowD<10 ? "0"+ nowD : nowD));             
            this.searchB.operationTime.push(lastY+"-"+(lastM<10 ? "0" + lastM : lastM)+"-"+(lastD<10 ? "0"+ lastD : lastD));
            this.searchB.operationTime.push(nowY+"-"+(nowM<10 ? "0" + nowM : nowM)+"-"+(nowD<10 ? "0"+ nowD : nowD)); 
            //默认时间  一加载进来就默认时间 发送请求
            this.RequestData.beginTime = lastY+"-"+(lastM<10 ? "0" + lastM : lastM)+"-"+(lastD<10 ? "0"+ lastD : lastD);
            this.RequestData.endTime = nowY+"-"+(nowM<10 ? "0" + nowM : nowM)+"-"+(nowD<10 ? "0"+ nowD : nowD);
    },
    // 每页条数
    SizeChange(newsize) {
        if(this.showCenter == true ){
                let data = this.RequestData;
                data.pageSize = newsize;
            this.getCenterCloud(data);
        }else{
            let data = this.RequestData;
                data.resourceType = 1;
                data.pageSize = newsize;
            this.getSaasCloud(data)
        }
    },
    //当前页码
    CurrentChange(newNum){
            if(this.showCenter == true ){
                let data = this.RequestData;
                data.pageNum = newNum;
            this.getCenterCloud(data);
        }else{
            let data = this.RequestData;
                data.resourceType = 1;
                data.pageNum = newNum;
            this.getSaasCloud(data)
        }
    },
//获取数据
    getLogLists(data){
        // console.log(data)
        // console.log("请求带参")
        _getLogLists(data)
        .then((res)=>{
            if(res.success){
                //数据相关
                // console.log(res)
                // console.log("获取数据")
                if(this.showCenter == true){
                        this.centerData = res.data.list;
                }else{
                    this.saasData = res.data.list;
                }
                //分页相关
                this.totalCount = res.data.total;
                this.pageSize = res.data.pageSize;
                this.pageNum = res.data.pageNum;
            }else{
                this.$message.error(res.message)
            }
        })
    },
//获取 所有组织下拉框的数据   与  所有操作项的数据 
    //组织
    getCompanyIdList(){
        _getCompanyId().then((res)=>{
            if(res.success){
                let newArr = [];
                    res.data.forEach(item =>{
                        newArr.push({
                            id: item.id,
                            companyName: item.companyName 
                        })
                    })
                    this.companyIdList = newArr
            }else{
                this.$message.error(res.message)
            }       
        })
    },
    //操作项
    getoperationTypeCenter(){
           this.flag=0
           _getoperationType({flag:this.flag}).then((res)=>{
            console.log(res)
            if(res.success){
                res.data.forEach(item =>{
                    // console.log(res)
                    this.operationTypeListA.push({value: item,lable: item})
                }) 
            }else{
                this.$message.error(res.messaeg)
            }
        })    
    },
    getoperationTypeSaas(){
            this.flag=1
            _getoperationType({flag:this.flag}).then((res)=>{
            console.log(res)
            if(res.success){
                res.data.forEach(item =>{
                    // console.log(res)
                    this.operationTypeListB.push({value: item,lable: item})
                }) 
            }else{
                this.$message.error(res.messaeg)
            }
        })
    
    },
     //获取中心云数据
    getCenterCloud(){ 
        // this.getNowFormatDate();//初始时间
        this.showCenter = true;
        this.resourceType = 0;
        let data = this.RequestData;    
         data.resourceType = 0;  
        this.getLogLists(data)
    },
    //获取saas云数据
    getSaasCloud(){
        // this.getNowFormatDate();//初始时间
        this.showCenter = false;
        this.resourceType = 1;
        this.clearSearch;
        let data = this.RequestData;
            data.resourceType = 1
        this.getLogLists(data)
    },
    //中心云 搜索
   searchCenter(){
       let data = this.RequestData;
       data.resourceType = 0;
       data.resource = this.searchA.resource
       data.operationType = this.searchA.operationType
       data.pageNum=1
       if(this.searchA.operationTime instanceof Array){
             data.beginTime = this.searchA.operationTime[0]
             data.endTime = this.searchA.operationTime[1]
       }else{
           data.beginTime = '';
           data.endTime = '';
       }
        this.getCenterCloud(data)
        // console.log(typeof this.searchA.operationTime)
        // console.log( this.searchA.operationTime)
    },
    //saas云搜索
    searchSaas(){
        let data = this.RequestData;
        data.resourceType = 1;
        data.companyId = this.searchB.companyId
        data.resource = this.searchB.resource
        data.operationType = this.searchB.operationType
        data.pageNum=1
         if(this.searchB.operationTime instanceof Array){
             data.beginTime = this.searchB.operationTime[0]
             data.endTime = this.searchB.operationTime[1]
       }else{
           data.beginTime = '';
           data.endTime = '';
       }
        this.getSaasCloud(data)
    },
    //清除clearSearch
    clearSearch(){
        let data = this.RequestData;
        this.pageNum=1;
        this.pageSize=10;
        if(this.showCenter){
            this.searchA.resource='',//操作项
            this.searchA.operationTime=[],//操作时间
            this.searchB.operationTime=[],//操作时间
            this.searchA.operationType=''
            data.pageNum=1;
            data.pageSize=10;
            data.resourceType=0;
            data.resource = ''
            data.operationType = ''
            this.getNowFormatDate()//获得时间  
            this.getCenterCloud(data)  
        }else{
            this.searchB.resource='',//操作项
            this.searchB.operationTime=[],//操作时间
            this.searchA.operationTime=[],//操作时间
            this.searchB.companyId=''
            this.searchB.operationType=''
              data.pageNum=1;
            data.pageSize=10;
            data.resourceType=1;
            data.companyId=''
            data.resource = ''
            data.operationType = ''
            this.getNowFormatDate()//获得时间  
            this.getSaasCloud(data)
        }   
    },
   // 导出excel数据
    getDowload() { 
     // 请求表
        if(this.showCenter == true){
            let totalCount = this.totalCount
            let data = JSON.parse(JSON.stringify(this.RequestData));
                data.resourceType =0;
                data.pageSize = totalCount;//将total数量变成一页数量
                console.log(data)
                _getLogLists(data).then(res=>{
                   this.exportTable = res.data.list
                //  做操作
                // 表头对应关系
                const headers = {
                    所属组织:"companyName",
                    操作类型:"operationType",
                    操作项:"resource",
                    参数:"params",
                    操作时间:"operationTime",
                    操作人:"username"
                };
                // 懒加载
                import("@/until/Export2Excel").then(async excel => {
                    let rows = this.exportTable;
                    const data = this.formatJson(headers, rows);
                    excel.export_json_to_excel({
                    header: Object.keys(headers),
                    data,
                    filename: "日志管理记录",
                    autoWidth: true,
                    bookType: "xlsx"
                    });
                });
            })
        }else{
            let totalCount = this.totalCount
            let data = JSON.parse(JSON.stringify(this.RequestData));
                data.resourceType =1;
                data.pageSize = totalCount;//将total数量变成一页数量
                _getLogLists(data).then(res=>{
                    this.exportTable = res.data.list
                //  做操作
                // 表头对应关系
                const headers = {
                    所属组织:"companyName",
                    操作类型:"operationType",
                    操作项:"resource",
                    参数:"params",
                    操作时间:"operationTime",
                    操作人:"username"
                };
                // 懒加载
                import("@/until/Export2Excel").then(async excel => {
                    let rows= this.exportTable;
                    const data = this.formatJson(headers, rows);
                    excel.export_json_to_excel({
                    header: Object.keys(headers),
                    data,
                    filename: "日志管理记录",
                    autoWidth: true,
                    bookType: "xlsx"
                    });
                });
            })
        }  
    },
    // 该方法负责将数组转化成二维数组
    formatJson(headers, rows) {
      // 首先遍历数组
      // [{ username: '张三'},{},{}]  => [['张三'],[],[]]
      return rows.map(item => {
        return Object.keys(headers).map(key => {
          return item[headers[key]];
        }); // => ["张三", "13811"，"2018","1", "2018", "10002"]
      });
    }
  },
};
</script>
<style scoped>
.textcolor {
  font-size: 16px;
  color: #666666;
  cursor: pointer;
  margin-right: 61px;
}
.statutext {
  color: rgba(22, 131, 217, 1);
  font-size: 16px;
  margin-right: 61px;
  cursor: pointer;
}
.second_row{
    margin:1% 0 1% 0;
}
.pickerTime{border:0px solid black;margin:0 1% 0 1%}
</style>
